<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import MenuLeilao from './menu/MenuLeilao'
import {UFakeLoader} from 'uloc-vue'
import {find, stats} from '@/domain/leiloes/services'
import MenuSuperior from '@/components/leiloes/components/menu/MenuSuperior'
import LeilaoMixin from '@/mixins/LeilaoMixin'
import ConsoleMenuItem from "components/console/components/MenuItem.vue"
import BtnStatusLeilao from "components/leiloes/components/context/BtnStatusLeilao.vue"
import AlteracaoStatusLeilaoMixin from "components/leiloes/components/include/AlteracaoStatusLeilaoMixin"
import BtnStatusInternoLeilao from "components/leiloes/components/context/BtnStatusInternoLeilao.vue"
import BtnAcoesLeilao from "components/leiloes/components/context/BtnAcoesLeilao.vue"
import FaturamentoTabs from "components/leiloes/components/include/FaturamentoTabs.vue"
import NavBtn from "components/leiloes/components/layout-components/NavBtn.vue";

export default {
  name: 'LeilaoPage',
  provide: function () {
    return {
      page: this,
      leilaoPage: this
    }
  },
  mixins: [DefaultMixin, LeilaoMixin, AlteracaoStatusLeilaoMixin],
  components: {
    NavBtn,
    FaturamentoTabs,
    BtnAcoesLeilao,
    BtnStatusInternoLeilao,
    BtnStatusLeilao,
    ConsoleMenuItem,
    MenuSuperior,
    MenuLeilao,
    UFakeLoader
  },
  data () {
    return {
      leilao: null,
      active: 'hom',
      seguirLeilao: false,
      loadingStats: true,
      stats: null,
      menu: [
        {name: 'resumo', label: 'Resumo', icon: 'fal fa-home', route: 'leiloes.dashboard'},
        {name: 'preparacao', label: 'Preparação', icon: 'fal fa-hourglass-start', route: 'leiloes.preparacao.loteamento', parentRoute: 'leiloes.preparacao'},
        {name: 'lotes', label: 'Lotes', icon: 'fal fa-list', route: 'leiloes.show'},
        {name: 'habilitados', label: 'Habilitados', icon: 'fal fa-users', route: 'leiloes.habilitacoes'},
        {name: 'realizacao', label: 'Realização', icon: 'fal fa-gavel', route: 'leiloes.realizacao'},
        {name: 'fechamento', label: 'Fechamento', icon: 'fal fa-check-to-slot', route: 'leiloes.fechamento'},
        {name: 'supervisao', label: 'Supervisão', icon: 'fal fa-scanner-keyboard', route: 'leiloes.supervisao'},
        {name: 'automacao', label: 'Automação', icon: 'fal fa-robot', route: 'leiloes.automacao'},
        {name: 'marketing', label: 'Marketing', icon: 'fal fa-megaphone', route: 'leiloes.marketing'},
        {name: 'historico', label: 'Histórico', icon: 'fal fa-clock-rotate-left', route: 'leiloes.historico'},
        {name: 'documentos', label: 'Relatórios', icon: 'fal fa-book', route: 'leiloes.relatorios'},
      ],
    }
  },
  computed: {},
  mounted () {
    document.body.classList.add('app-leilao-body')
    this.load()
  },
  beforeDestroy() {
    document.body.classList.remove('app-leilao-body')
  },
  destroyed () {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load () {
      console.log('Load leilão data')
      this.leilao = null
      find(this.$route.params.id)
        .then((response) => {
          this.leilao = response.data
          try {
            this.loadingStats = true
            stats(this.leilao.id)
                .then(response => {
                  this.stats = response.data
                  this.loadingStats = false
                })
                .catch(error => {
                  this.alertApiError(error)
                })
          } catch (e) {
            console.error(e)
          }
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar o leilão!',
            color: 'negative',
            message: `Não conseguimos carregar o leilão, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    setActive (v) {
      this.active = v
    }
  },
  meta: {
    title: 'Leilão',
    name: 'Leilão'
  }
}
</script>

<template>
  <div class="leilao-page-container">
    <div v-if="leilao" class="ev2-menu w-menub leilao-menu min">
      <div class="def-width">
        <div class="content destaq">
          <div class="tab leilao-page">
            <div class="l-header">
            <nav-btn />
            </div>
          </div>
          <div class="tab">
            <div class="title">Leilão</div>
            <div class="val">{{leilao.codigo}}</div>
          </div>
          <div class="tab">
            <div class="title">ID</div>
            <div class="val">{{leilao.id}}</div>
          </div>
<!--          <div class="tab">
            <div class="title">Leiloeiro</div>
            <div class="val">{{firstLastname(leilao.leiloeiro.nome)}}</div>
          </div>-->
          <div class="tab">
            <div class="title">Lotes</div>
            <div class="val">{{leilao.totalLotes|number}}</div>
          </div>
          <div class="tab">
            <div class="title">Situação</div>
            <div class="val0">
              <btn-status-leilao style="max-width: 160px" @updateStatus="updateStatus" :loading="loadingUpdateStatus"
                                 :leilao="leilao" />
            </div>
          </div>
          <div class="tab">
            <div class="title">Preparação</div>
            <div class="val0">
              <btn-status-interno-leilao style="max-width: 160px"
                                         @alteraStatusInterno="updateStatusInterno"
                                         @prepararLeilao="(s) => updateStatusInterno(s)"
                                         :loading="loadingUpdateStatus"
                                 :leilao="leilao" />
            </div>
          </div>
          <faturamento-tabs />
          <!--<div class="tab">
            <div class="title">Tarefas</div>
            <div class="val">0</div>
          </div>
          <div class="tab">
            <div class="title">Tarefas Atrasadas</div>
            <div class="val">0</div>
          </div>
          <div class="tab">
            <div class="title">Bônus</div>
            <div class="val">R$ 0,00 <small class="font-10">(em breve)</small></div>
          </div>-->
          <div class="tab full text-right">
          </div>
        </div>
        <div class="menu-bottom content-menu">
          <console-menu-item
              v-for="m in menu"
              :key="m.name"
              :name="m.name"
              :icon="m.icon"
              :label="m.label"
              :route="m.route"
              :disabled="m.disabled"
              :parent-route="m.parentRoute"
          />
        </div>
      </div>
    </div>
    <div v-if="leilao" class="flex col-grow no-wrap leilao-page">
  <!--    <menu-superior />-->
<!--      <menu-leilao :active="active" :leilao="leilao" />-->
      <div class="app-layout-cmenu" style="padding-right: 0">
        <transition name="slide" appear>
          <div class="transition-generic">
            <router-view />
          </div>
        </transition>
      </div>
    </div>
    <div v-else class="flex col-grow no-wrap items-center justify-center default-loading leilao-loading">
      <div class="full-width wrapper-xl full-height self-start">
        <u-fake-loader width="100%" height="40px" />
        <u-fake-loader width="20%" />
        <u-fake-loader width="32%" />
        <u-fake-loader width="56%" />
        <br>
        <br>
        <br>
        <br>
        <br>
        <u-fake-loader width="32%" />
        <u-fake-loader width="10%" />
        <u-fake-loader width="78%" />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" src="./leilao.styl" />
