<template>
  <form v-if="opened" @submit.prevent="save" :class="{opened: showed}" class="sl-modal modal-pesssoa-cadastro">
    <div class="w-m-header">
      <h2 v-if="!pessoa.id">Novo cadastro</h2>
      <h2 v-else>Editar cadastro ID {{pessoa.id}}</h2>
      <button @click="close" type="button" class="btn-close">
        <i class="fal fa-times" />
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size2">
      <div class="body-content">
        <e-row mr>
          <e-col style="max-width: 200px">
            <tipo-pessoa-select required label="Tipo de pessoa" v-model="pessoa.type" />
          </e-col>
          <e-col style="max-width: 300px">
            <erp-s-field :label="labelDoc">
              <documento-pessoa-input :pessoa="pessoa" v-model="pessoa.document" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Nome" required>
              <!--          <erp-input required autofocus v-model="pessoa.name" />-->
              <nome-pessoa-input ref="nomeInput" :router="router" autofocus v-model="pessoa.name"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row v-if="pessoa.type != 2" mr>
          <e-col>
            <erp-s-field label="Organização / Empresa relacionada à esta pessoa">
              <autocomplete :search="searchOrganizacao" v-model="pessoa.organization" icon="building"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field label="Código do cadastro" :icon-help="'Antigo código do cliente.'">
              <erp-input v-model="pessoa.code" />
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row mr>
          <e-col>
            <erp-s-field label="Tipo de papel" icon-help="Item não obrigatório. Escolha um ou mais papéis que esta pessoa assumirá no sistema. Para mais papéis utilize o campo 'Tag(s) e outros papéis'">
              <e-row mr>
                <e-col v-for="paper in papers" :key="'paper-' + paper.id" style="max-width: none; min-width: auto; flex-grow: 0">
                  <label class="no-select flex items-center text-nowrap no-wrap">
                    <u-checkbox :val="paper.id" v-model="pessoa.papers" class="m-r-xs" />
                    <span class="m-r-xs">{{ paper.name }}</span>
                  </label>
                </e-col>
              </e-row>
            </erp-s-field>
          </e-col>
        </e-row>

        <e-row class="m-b">
          <e-col>
            <erp-s-field label="Tag(s) e outros papéis">
              <autocomplete-multiple load-on-focus :search="searchPersonTags" v-model="pessoa.tags" placeholder="Nenhum"/>
            </erp-s-field>
          </e-col>
        </e-row>

        <collapse :collapse="false" title="Informações adicionais">
          <e-row mr>
            <e-col>
              <erp-s-field :label="pessoa.type == 2 ? 'Data de fundação' : 'Data de nascimento'">
                <erp-input v-model="pessoa.birthDate" v-mask="'##/##/####'"/>
              </erp-s-field>
            </e-col>
            <e-col v-if="pessoa.type != 2">
              <erp-s-field label="Nacionalidade">
                <erp-input v-model="pessoa.nationality" />
              </erp-s-field>
            </e-col>
            <e-col v-if="pessoa.type != 2">
              <erp-s-field label="Gênero">
                <erp-select v-model="pessoa.gender" :options="[
                    {label: 'Masculino', value: 1},
                    {label: 'Feminino', value: 2},
                ]"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <erp-s-field label="E-mail" class="m-t">
            <e-row v-for="(email, index) in pessoa.emails" :key="'email-' + index">
              <e-col class="m-r-xs">
                <erp-input v-model="email.email"/>
              </e-col>
              <e-col class="m-l-xs">
                <tipo-email-select required select-first no-label v-model="email.purpose"/>
              </e-col>
              <e-col v-if="pessoa.emails.length > 1" class="flex items-center" style="max-width: 20px">
                <a class="m-l-xs" @click="removeEmail(email)">
                  <u-icon name="trash" type="fa" color="grey-7"/>
                </a>
              </e-col>
            </e-row>
            <div><a @click="adicionarEmail" class="font-bold">+ Adicionar email</a></div>
          </erp-s-field>

          <erp-s-field label="Telefone" class="m-t">
            <e-row v-for="(telefone, index) in pessoa.phoneNumbers" :key="'phone-' + index">
              <e-col class="m-r-xs">
                <erp-input v-model="telefone.phoneNumber"/>
              </e-col>
              <e-col class="m-l-xs">
                <tipo-telefone-select required select-first no-label v-model="telefone.purpose"/>
              </e-col>
              <e-col v-if="pessoa.phoneNumbers.length > 1" class="flex items-center" style="max-width: 20px">
                <a class="m-l-xs" @click="removeTelefone(telefone)">
                  <u-icon name="trash" type="fa" color="grey-7"/>
                </a>
              </e-col>
            </e-row>
            <div><a @click="adicionarTelefone" class="font-bold">+ Adicionar telefone</a></div>
          </erp-s-field>

        </collapse>

        <collapse v-for="(address, key) in pessoa.addresses" :key="'address-' + key" :collapse="false" title="Endereço">
          <e-row mr>
            <e-col style="max-width: 250px">
              <erp-s-field label="País">
                <erp-select disable v-model="address.countryId" :options="[
                    {label: 'Brasil', value: 1},
                ]"/>
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 280px">
              <erp-s-field label="CEP">
                <cep-input @cep="() => focusByClass('addressNumber' + key)" :person="pessoa" :address="address" v-model="address.zip" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="Endereço">
                <erp-input v-model="address.address"/>
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 160px">
              <erp-s-field label="Número">
                <erp-input :class="'addressNumber' + key" v-model="address.number"/>
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row mr>
            <e-col style="max-width: 260px">
              <erp-s-field label="UF">
                <uf-select :city="address.city" v-model="address.state" @preventCitySelected="(city) => address.city = city" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 260px">
              <erp-s-field label="Cidade">
                <cidade-select :uf="address.state" v-model="address.city" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="Bairro">
                <erp-input v-model="address.district"/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field label="Complemento">
                <erp-input v-model="address.complement"/>
              </erp-s-field>
            </e-col>
          </e-row>
        </collapse>

        <collapse :collapse="false" title="Observações gerais">
          <erp-s-field class="m-t" label="Observações">
            <textarea class="sl-textarea full-width" rows="5" v-model="pessoa.description"></textarea>
          </erp-s-field>
        </collapse>

      </div>
    </div>
    <div class="w-m-footer text-right footer-modern">
      <div><!--{{pessoa.papers}} - {{pessoa.tags}}--></div>
      <u-btn :loading="loading" type="submit" label="Salvar" no-caps
             :color="'green'"
             class="app-btn b-radius-3px"/>
    </div>
  </form>
</template>

<script>
import ERow from '../../../layout/components/Row'
import {UTooltip, UCheckbox} from 'uloc-vue'
import {ErpInput, ErpSelect, ErpSField} from 'uloc-vue-plugin-erp'
import ECol from '../../../layout/components/Col'
import Autocomplete from "@/components/layout/components/Autocomplete"
import searchOrganizacao from "@/components/pessoa/helpers/searchOrganizacao"
import searchTags from "@/components/pessoa/helpers/searchTags"
import AutocompleteMultiple from "@/components/layout/components/AutocompleteMultiple"

import {mockTelefone} from "@/components/pessoa/mock/telefone"
import {mockEmail} from "@/components/pessoa/mock/email"
import {mockEndereco} from "@/components/pessoa/mock/endereco"
import TipoTelefoneSelect from "@/components/pessoa/components/include/TipoTelefoneSelect"
import TipoEmailSelect from "@/components/pessoa/components/include/TipoEmailSelect"
import PersonMixin from "@/components/pessoa/components/include/PersonMixin"
import NomePessoaInput from "@/components/pessoa/components/include/NomePessoaInput"
import {createPerson, find, updatePerson} from "@/domain/pessoa/services"
import TipoPessoaSelect from "@/components/pessoa/components/include/TipoPessoaSelect"
import DocumentoPessoaInput from "@/components/pessoa/components/include/DocumentoPessoaInput"
import Collapse from "@/components/globalconfig/components/include/Collapse"
import CepInput from "@/components/pessoa/components/include/CepInput"
import UfSelect from "@/components/pessoa/components/include/UfSelect"
import CidadeSelect from "@/components/pessoa/components/include/CidadeSelect"

const mock = {
  type: 1,
  name: null,
  document: null,
  organization: null,
  birthDate: null,
  description: null,
  tags: [],
  phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone))],
  emails: [JSON.parse(JSON.stringify(mockEmail))],
  addresses: [JSON.parse(JSON.stringify(mockEndereco))],
  codwe: null,
  papers: []
}

export default {
  name: 'PessoaCadastro',
  mixins: [PersonMixin],
  components: {
    CidadeSelect,
    UfSelect,
    CepInput,
    Collapse,
    DocumentoPessoaInput,
    TipoPessoaSelect,
    NomePessoaInput,
    TipoEmailSelect,
    TipoTelefoneSelect,
    AutocompleteMultiple,
    Autocomplete,
    // WindowLoading,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    UTooltip,
    ErpSelect,
    UCheckbox
    // TimerCloseWindow,
    // WindowSuccess,
    // ErpCheckbox
  },
  props: ['options', 'router', 'type', 'mountBody'],
  data() {
    return {
      opened: false,
      showed: false, // helper to effects
      loading: false,
      success: false,
      status: '',
      papers: [
        {id: 'fornecedor', name: 'Fornecedor'},
        {id: 'parceiro', name: 'Parceiro'},
        {id: 'arrematante', name: 'Arrematante'},
        {id: 'advogado', name: 'Advogado'},
        {id: 'comitente', name: 'Comitente'},
        {id: 'vistoriador', name: 'Vistoriador'},
        {id: 'reboquista', name: 'Reboquista'},
        {id: 'leiloeiro', name: 'Leiloeiro(a)'},
        // {id: 'parte', name: 'Parte processual'},
        // {id: 'colaborador', name: 'Colaborador interno'},
      ],
      pessoa: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted() {
    if (this.mountBody) {
      document.body.append(this.$el)
    }
  },
  beforeDestroy() {
    if (this.mountBody) {
      document.body.contains(this.$el) && document.body.removeChild(this.$el)
    }
  },
  computed: {
    labelDoc () {
      if (!this.pessoa.type || this.pessoa.type == 1) {
        return 'CPF'
      } else if (this.pessoa.type == 2) {
        return 'CNPJ'
      }
      return 'Identificação / Passaporte'
    }
  },
  methods: {
    toggle () {
      this.opened ? this.close() : this.show()
    },
    show (id = null) {
      const open = () => {
        this.opened = true
        setTimeout(() => {
          this.showed = true
          this.$refs.nomeInput.focus()
          if (this.$route.query.paper) {
            this.pessoa.papers.push(this.$route.query.paper)
          }
        }, 20)
      }
      if (id) {
        this.$uloc.loading.show()
        find(id).then(response => {
          let data = response.data
          if (!data.phoneNumbers || !data.phoneNumbers.length) {
            data.phoneNumbers = [JSON.parse(JSON.stringify(mockTelefone))]
          }
          if (!data.emails || !data.emails.length) {
            data.emails = [JSON.parse(JSON.stringify(mockEmail))]
          }
          if (!data.addresses || !data.addresses.length) {
            data.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
          }
          data.papers = data.tags.filter(t => !!this.papers.find(p => p.id === t.name.toLowerCase())).map(p => p.name.toLowerCase())
          data.tags = data.tags.filter(t => !data.papers.find(p => p === t.name.toLowerCase())).map(t => {
            return t.name
          })
          if (data.organization) {
            data.organization = data.organization.name
          }
          console.log(data.papers)
          this.pessoa = data
          this.$uloc.loading.hide()
          open()
        }).catch(error => {
          this.alertApiError(error)
          this.$uloc.loading.hide()
        })
      } else {
        this.pessoa = JSON.parse(JSON.stringify(mock))
        open()
      }
    },
    close () {
      this.showed = false
      setTimeout(() => {
        this.opened = false
      }, 200)
    },
    save() {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.pessoa))
      const cb = (data) => {
        let m = createPerson
        if (this.pessoa.id) {
          m = updatePerson
        }
        m(data)
            .then(response => {
              this.loading = false
              this.$emit('updated', response.data)
              if (this.pessoa.id) {
                this.dg()
              } else {
                this.close()
                this.success = true
                this.$uloc.notify({
                  color: 'black',
                  message: `Nova pessoa "${response.data.name}" criada.`,
                  position: 'bottom-left',
                  actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]
                })
              }
            })
            .catch(error => {
              this.loading = false
              this.alertApiError(error)
            })
      }
      try {
        if (data.organization && data.organization.id) {
          data.organization = data.organization.id
        }
        if (data.emails && data.emails.length) {
          data.emails = data.emails.filter(e => e.email && String(e.email).length > 5)
        }
        if (data.phoneNumbers && data.phoneNumbers.length) {
          data.phoneNumbers = data.phoneNumbers.filter(e => e.phoneNumber && String(e.phoneNumber).length > 3)
        }
        if (data.addresses[0].city && data.addresses[0].city.id) {
          data.addresses[0].cityId = data.addresses[0].city.id
          data.addresses[0].city = data.addresses[0].city.label
        }

        if (data.type == 2) {
          data.gender = null
        }
        /* if (data.tags && data.tags.length) {
          data.tags = data.tags.map(tag => tag.id)
        } */
        if (!this.pessoa.id && this.$refs.nomeInput.duplicates && this.$refs.nomeInput.duplicates.length) {
          this.$uloc.dialog({
            title: 'Possível pessoa duplicada',
            message: 'Existem nomes parecidos com este, você tem certeza que deseja continuar com o cadastro?',
            ok: 'Sim',
            cancel: 'Não',
            color: 'negative'
          }).then(() => {
            cb(data)
          })
          .catch(() => {
            this.loading = false
          })
        } else {
          cb(data)
        }
      } catch (e) {
        this.alertApiError(null, 'Erro de validação', null, e.message)
        this.loading = false
        return
      }

    },
    searchOrganizacao(terms, done) {
      searchOrganizacao(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova organização`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    searchPersonTags(terms, done) {
      searchTags(terms)
          .then(response => {
            const extra = {
              label: `Adicionar "${terms}" como nova tag`,
              value: terms,
              isNew: true
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            result.push(extra)
            done(result)
          }).catch(error => {
        this.alertApiError(error)
      })
    },
    gotoPerson (p) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'pessoa.show', params: {id: p.id}})
      window.open(routeData.href, '_blank')
    },
    focusByClass (css) {
      setTimeout(() => {
        const seletor = document.querySelector('.' + css + ' input')
        seletor && seletor.focus()
      }, 100)
    }
  }
}
</script>
