import http from '../../../utils/services/http'

export const dashboard = () => {
  return http.get('/api/arrematante/resumeDashboard')
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros, basic) => {
  let url = '/api/arrematantes?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/arrematantes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newArrematante = (data) => {
  let url = `/api/arrematantes`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateArrematante = (id, data) => {
  let url = `/api/arrematantes/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteArrematante = (id) => {
  let url = `/api/arrematantes/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateFotoArrematante = (id, data) => {
  let url = `/api/arrematantes/${id}/photo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const removePersonObject = (type, person, object) => {
  let url = `/api/pessoa/${person}/${type}/${object}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findByDoc = (doc) => {
  let url = `/api/arrematantes/findByDoc/${doc}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findByDoc2 = (doc) => { // TMP
  // let url = `/api/arrematante/findByDoc/${doc}`
  let url = '/api/arrematanteExemplo2'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const pendenciasVenda = (id, status = '0,1') => {
  if (status !== null) {
    status = `&status=${status}`
  }
  let url = `/api/arrematantes/${id}/vendas?limit=500&page=1${status}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const pendenciasVendaDetalhes = (id) => {
  let url = `/api/arrematantes/${id}/vendasPendentes`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const logArrematante = (id) => {
  // let url = `/api/arrematante/${id}/logs`
  let url = '/api/logArrematante'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const historicoLances = (arrematante, limit, page, filtros, basic) => {
  // let url = '/api/arrematante?page=' + page + '&limit=' + limit + filtros
  let url = '/api/lancesArrematante'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const historicoLeiloes = (arrematante, limit, page, filtros, basic) => {
  // let url = '/api/historicoLeiloesArrematante?page=' + page + '&limit=' + limit + filtros
  let url = '/api/historicoLeiloesArrematante'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const verificaApelido = (apelido) => {
  let url = `/api/public/arrematante/verificarApelido/${apelido}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const uploadDocumento = (id, data) => {
  let url = `/api/arrematantes/${id}/documentos`
  return http.post(url, data, {
    'timeout': 300000
    // headers: {'Content-Type': 'multipart/form-data'}
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteDocumento = (arrematante, arquivo) => {
  let url = `/api/arrematantes/${arrematante}/documentos/${arquivo}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateDocumento = (arrematante, arquivo, data) => {
  let url = `/api/arrematantes/${arrematante}/documentos/${arquivo}`
  return http.patch(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateStatusDocumento = (arrematante, arquivo, data) => {
  let url = `/api/arrematantes/${arrematante}/documentos/${arquivo}/status`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const downloadDocumento = (arrematante, arquivo) => {
  let url = `/api/arrematantes/${arrematante}/documentos/${arquivo}/download`
  return http.get(url/*, {
    responseType: 'blob',
    timeout: 30000
  }*/)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const resumoFinanceiro = (id) => {
  let url = `/api/arrematantes/${id}/resumoFinanceiro`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const getCountStatusCadastro = () => {
  let url = `/api/arrematantes/count-status-cadastros`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const analisarCadastro = (arrematante) => {
  const url = `/api/arrematantes/${arrematante}/analisarCadastro`
  return http.get(url)
      .then(response => {
        console.log(response)
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const analisesCadastrais = (arrematante) => {
  const url = `/api/arrematantes/${arrematante}/analisesCadastrais`
  return http.get(url)
      .then(response => {
        console.log(response)
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const webhookCall = (id) => {
  let url = `/api/arrematantes/${id}/webhook`
  return http.post(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export default {
  dashboard,
  list,
  find,
  findByDoc,
  findByDoc2,
  pendenciasVenda,
  logArrematante,
  historicoLances,
  historicoLeiloes,
  getCountStatusCadastro
}
