// import Main from './Main'
import ListaLeiloes from './components/ListaLeiloes'
import LeilaoPage from './components/LeilaoPage'
import Leilao from './components/Leilao'
import MonitorComitente from './components/monitor-comitente/Monitor'
import MonitorLeilao from './components/monitoramento/Monitor'

export default [
  // { path: '/leiloes', component: Main, name: 'leiloes.main' },
  {path: '/leiloes/monitoramento', component: MonitorLeilao, name: 'leiloes.monitor'},
  {path: '/leiloes/auditorio', component: () => import('./components/auditorio/AuditorioUnificado.vue'), name: 'leiloes.auditorio-unificado'},
  {path: '/leiloes', component: ListaLeiloes, name: 'leiloes.lista',  children: [
      {
        path: ':id', component: LeilaoPage, name: 'leiloes.page', children: [
          {
            path: '',
            name: 'leiloes.dashboard',
            component: () => import('./components/Dashboard.vue')
          },
          {
            path: 'lotes',
            name: 'leiloes.show',
            component: Leilao
          },
          {
            path: 'preparacao',
            name: 'leiloes.preparacao',
            component: () => import('./components/Preparacao.vue'),
            children: [
              {
                path: '',
                name: 'leiloes.preparacao.loteamento',
                component: () => import('./components/preparacao/Loteamento.vue')
              },
              {
                path: 'importacao',
                name: 'leiloes.preparacao.importacao',
                component: () => import('./components/preparacao/Importacao.vue')
              },
              {
                path: 'importacaoAvancada',
                name: 'leiloes.importacao-avancada',
                component: () => import('./components/importacao/Home.vue')
              }
            ]
          },
          {
            path: 'realizacao',
            name: 'leiloes.realizacao',
            component: () => import('./components/Realizacao.vue')
          },
          {
            path: 'fechamento',
            name: 'leiloes.fechamento',
            component: () => import('./components/Realizacao.vue')
          },
          {
            path: 'supervisao',
            name: 'leiloes.supervisao',
            component: () => import('./components/Realizacao.vue')
          },
          {
            path: 'automacao',
            name: 'leiloes.automacao',
            component: () => import('./components/Realizacao.vue')
          },
          {
            path: 'marketing',
            name: 'leiloes.marketing',
            component: () => import('./components/Realizacao.vue')
          },
          {
            path: 'historico',
            name: 'leiloes.historico',
            component: () => import('./components/Realizacao.vue')
          },
          {
            path: 'monitor-comitente',
            name: 'leiloes.monitor-comitente',
            component: MonitorComitente
          },
          {
            path: 'acompanhamento-comitente',
            name: 'leiloes.acompanhamento-comitente',
            component: () => import('./components/monitor-comitente/Acompanhamento.vue')
          },
          {
            path: 'acompanhamento-comitente2',
            name: 'leiloes.acompanhamento-comitente2',
            component: () => import('./components/monitor-comitente/Acompanhamento2.vue')
          },
          {
            path: 'controlador',
            name: 'leiloes.controlador',
            component: () => import('./components/controlador/ControladorV2.vue')
          },
          {
            path: 'leiloeiro',
            name: 'leiloes.tela-leiloeiro',
            component: () => import('./components/tela-leiloeiro/Leiloeiro.vue')
          },
          {
            path: 'apresentacao-telao',
            name: 'leiloes.apresentacao-telao-seletor',
            component: () => import('./components/apresentacao-telao/Seletor.vue')
          },
          {
            path: 'apresentacao-telao-black1',
            name: 'leiloes.apresentacao-telao-black1',
            component: () => import('./components/apresentacao-telao/Black/Black1.vue')
          },
          {
            path: 'apresentacao-telao-black2',
            name: 'leiloes.apresentacao-telao-black2',
            component: () => import('./components/apresentacao-telao/Black/Black2.vue')
          },
          {
            path: 'apresentacao-lotes',
            name: 'leiloes.apresentacao-lotes-seletor',
            component: () => import('./components/apresentacao-lotes/Seletor.vue')
          },
          {
            path: 'live',
            name: 'leiloes.live',
            component: () => import('./components/transmissao/Live.vue')
          },
          {
            path: 'relatorios',
            name: 'leiloes.relatorios',
            component: () => import('./components/relatorios/Dashboard.vue'),
            children: [
              {
                path: 'resumo',
                name: 'leiloes.relatorios.resumo',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'saida',
                name: 'leiloes.relatorios.saida',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'saidaArrematante',
                name: 'leiloes.relatorios.saida.arrematante',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'lances',
                name: 'leiloes.relatorios.lances',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'lancesComitente',
                name: 'leiloes.relatorios.lances.comitente',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'certidaoPublicacao',
                name: 'leiloes.relatorios.certidao.publicacao',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'certidaNegativa',
                name: 'leiloes.relatorios.certidao.negativa',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'certidaNegativa2',
                name: 'leiloes.relatorios.certidao.negativa2',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'numerosVisitas',
                name: 'leiloes.relatorios.numeros.visitas',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'diarioLeilao',
                name: 'leiloes.relatorios.diario.leilao',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'diarioLeilaoV2',
                name: 'leiloes.relatorios.diario.leilao-v2',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'lotesNaoVendidos',
                name: 'leiloes.relatorios.lotes.nao.vendidos',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'lotesCondicional',
                name: 'leiloes.relatorios.lotes.condicional',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'lotesVendidos',
                name: 'leiloes.relatorios.lotes.vendidos',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'lotesRetirados',
                name: 'leiloes.relatorios.lotes.retirados',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'termoAlienacao',
                name: 'leiloes.relatorios.termo.alienacao',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'arrematacaoLeilao',
                name: 'leiloes.relatorios.arrematacao.leilao',
                component: () => import('./components/relatorios/Selector.vue')
              },
              {
                path: 'arrematacaoLeilao2',
                name: 'leiloes.relatorios.arrematacao.auto2',
                component: () => import('./components/relatorios/Selector.vue')
              }
            ]
          },
          {
            path: 'cobranca',
            name: 'leiloes.cobranca',
            component: () => import('./components/cobranca/Home.vue')
          },
          {
            path: 'habilitacoes',
            name: 'leiloes.habilitacoes',
            component: () => import('./components/habilitacoes/Home.vue')
          },
          {
            path: 'lances-automaticos-configurados',
            name: 'leiloes.lances.automaticos',
            component: () => import('./components/lances-automaticos/Home.vue')
          }
        ]
      }
    ]},
  // Print
  {path: '/print/lotes/:id/recibo', component: () => import('./components/print/Recibo'), name: 'print.lote.recibo'},
  {path: '/print/lotes/:id/reciboComitente', component: () => import('./components/print/ReciboComitente'), name: 'print.lote.reciboComitente'},
]
